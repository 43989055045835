<template>
  <Dialog v-if="visible" v-model="visible" :options="dialogOptions.dialog">
    <TableLayout
      ref="TableLayout"
      :columns="columns"
      :query-format="queryFormat"
      :format-global-query="formatGlobalQuery"
      :url="`/${$businessISG}/monitor/${prefix}/${prefix}-page`"
    >
      <template slot="query-form" slot-scope="{ queryParam }">
        <QueryLayout v-if="!isSubbranch" lable="机构名称">
          <SelectTree
            v-model="queryParam.organizationUUID"
            :options="organizationTree"
            :replace-fields="{
              itemText: 'name',
              itemKey: 'uuid',
              itemChildren: 'children',
            }"
            placeholder="请选择机构名称"
          ></SelectTree>
        </QueryLayout>
        <QueryLayout lable="检测类型">
          <Select
            v-model="queryParam.searchAnalysisItems"
            :options="
              analysisItemList.length ? analysisItemList : allAnalysisItemList
            "
            :replace-fields="{
              lable: 'itemName',
              value: 'uuid',
            }"
            placeholder="请选择检测类型"
          ></Select>
        </QueryLayout>
        <QueryLayout lable="审核状态">
          <Select
            v-model="queryParam.handleFlag"
            :options="handleFlag"
            :replace-fields="{
              lable: 'msg',
              value: 'code',
            }"
            :is-radio="false"
            placeholder="请选择审核状态"
          ></Select>
        </QueryLayout>
        <QueryLayout lable="预警时间">
          <DatePicker v-model="queryParam.time" range></DatePicker>
        </QueryLayout>
      </template>
      <div
        slot="table-item-action"
        slot-scope="{ row }"
        style="cursor: pointer"
        @click="$refs.EarlyWarningHandleInfoDialog.openDialog({ row ,type:checkType(row)})"
      >
        <div style="color: #25c4ff">查看</div>
      </div>
    </TableLayout>
    <EarlyWarningHandleInfoDialog
      ref="EarlyWarningHandleInfoDialog"
      @success="$refs.TableLayout && $refs.TableLayout.getData()"
    ></EarlyWarningHandleInfoDialog>
  </Dialog>
</template>

<script>
import { mapState } from 'vuex';
import { assiginDeep } from '@/views/screen/config';
import Dialog from '@/views/screen/components/Dialog.vue';
import TableLayout from '@/views/screen/components/TableLayout.vue';
import QueryLayout from '@/views/screen/components/QueryLayout.vue';
import Select from '@/views/screen/components/Select.vue';
import SelectTree from '@/views/screen/components/SelectTree.vue';
import DatePicker from '@/views/screen/components/DatePicker.vue';
import EarlyWarningHandleInfoDialog from './EarlyWarningHandleInfoDialog.vue';
export default {
  //组件注册
  components: {
    Dialog,
    TableLayout,
    QueryLayout,
    Select,
    SelectTree,
    DatePicker,
    EarlyWarningHandleInfoDialog,
  },
  //组件传值
  props: {
    prefix: {
      type: String,
      default: 'home',
    },
    analysisItemList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      dialogOptions: {
        dialog: {
          width: 1500,
          title: '预警列表',
        },
      },
      columns: [
        {
          text: '序号',
          value: 'sortNo',
          sortable: false,
          align: 'center',
        },
        {
          text: '机构名称',
          value: 'organizationText',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作位置',
          value: 'cameraName',
          sortable: false,
          align: 'center',
        },
        {
          text: '检测类型',
          value: 'analysisItemText',
          sortable: false,
          align: 'center',
        },
        {
          text: '预警时间',
          value: 'warningTime',
          sortable: false,
          align: 'center',
        },
        {
          text: '检测结果',
          value: 'violationFlagText',
          sortable: false,
          align: 'center',
        },
        {
          text: '不合规理由',
          value: 'description',
          sortable: false,
          align: 'center',
        },
        {
          text: '审核状态',
          value: 'handleFlagText',
          sortable: false,
          align: 'center',
        },
        {
          text: '操作',
          value: 'action',
          sortable: false,
          align: 'center',
          itemSlots: true,
        },
      ],
    };
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
      organizationTree: (state) => state.screenStore.organizationTree,
      allAnalysisItemList: (state) =>
        state.screenStore.analysisitem['allAnalysisItemList']
          ? state.screenStore.analysisitem['allAnalysisItemList']
          : [],
      handleFlag: (state) =>
        state.screenStore.commondict['MonitorDataHandleFlagEnum']
          ? state.screenStore.commondict['MonitorDataHandleFlagEnum']
          : [],
    }),
  },
  //属性监听
  watch: {
    visible() {
      if (!this.visible) {
        this.dialogOptions = {
          dialog: {
            width: 1500,
            title: '预警列表',
          },
        };
      }
    },
  },
  //DOM访问
  mounted() {},
  //保存方法
  methods: {
    setCheckType(){},
    checkType(row){
      const cashTyps = ['卡把','出入库','行领导查库','清机','加钞']
      const serviesType = ['离岗','玩手机','抬脚上桌','吃外卖','笔记本电脑','睡岗','中午行休','夜间开灯','电脑关闭','大门打开/关闭']
      const ransactionType = ['老赖动账','风险交易']
      console.log(row)
      if(cashTyps.includes(row.analysisItemText)){
        return 'cashSupervision'
      }else if(serviesType.includes(row.analysisItemText)){
        return 'serviceSupervision'
      }else if(ransactionType.includes(row.analysisItemText)){
        return 'transactionRecheck'
      }else{
        return ''
      }
    //   const arr = []
    //   cashTyps.forEach(v=>{
    //     arr.push({
    //       name:v,
    //       type:'cashSupervision'
    //     })
    //   })
    //  serviesType.forEach(v=>{
    //    arr.push({
    //      name:v,
    //      type:'serviceSupervision'
    //    })
    //  })
    //  ransactionType.forEach(v=>{
    //    arr.push({
    //      name:v,
    //      type:'transactionRecheck'
    //    })
    //  })

    },
    formatGlobalQuery(globalQuery) {
      const query = {
        organizationUUID: [globalQuery.organizationUUID],
        ...this.dialogOptions.fixedParam,
      };
      if (globalQuery.timeStartStr && globalQuery.timeEndStr) {
        query.time = ['timeStartStr', 'timeEndStr'].map(
          (key) => globalQuery[key]
        );
      }
      return query;
    },
    queryFormat(query) {
      for (const key in query) {
        if (['organizationUUID'].includes(key) && Array.isArray(query[key])) {
          query[key] = query[key].toString();
        } else if (
          ['time'].includes(key) &&
          Array.isArray(query[key]) &&
          query[key].length == 2
        ) {
          Object.assign(
            query,
            ['StartStr', 'EndStr'].reduce(
              (val, suffix, i) => ({
                ...val,
                [`${key}${suffix}`]: query[key][i] ?? '',
              }),
              {}
            )
          );
          delete query[key];
        }
      }
      if (this.dialogOptions.query)
        query = Object.assign(query, this.dialogOptions.query);
      if (query.handleFlag) query.handleFlag = query.handleFlag.toString() || undefined;
      return query;
    },
    openDialog(options = {}) {
      this.dialogOptions = assiginDeep(this.dialogOptions, options);
      this.visible = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
